<template>
    <div class="sign">
        <div>
            <div>
                <el-form ref="form" :model="signForm" :rules="rules">
                    <div style="margin-bottom: 20px">
                        <div class="title-form"><span style="color: red; font-size: 16px">* </span>活动名称</div>
                        <el-form-item prop="title">
                            <el-input
                                    v-model="signForm.title"
                                    :show-word-limit="true"
                                    maxlength="30"
                                    placeholder="活动名称">
                            </el-input>
                        </el-form-item>
                    </div>

                    <div style="flex: 1;">
                        <div class="title-form"><span style="color: red; font-size: 16px">* </span>报名截止日期</div>
                        <el-form-item prop="endTime">
                            <el-date-picker
                                v-model="signForm.endTime"
                                type="datetime"
                                :picker-options="pickerOptions"
                                placement="bottom-start"
                                placeholder="选择日期及具体时间">
                            </el-date-picker>
                        </el-form-item>
                    </div>



                    <div style="display: flex; margin-bottom: 20px">
                        <div style="flex: 1;">
                            <div class="title-form"><span style="color: red; font-size: 16px">* </span>活动开始日期</div>
                            <el-form-item prop="activityTime">
                                <el-date-picker
                                    v-model="signForm.activityTime"
                                    :picker-options="pickerOptions"
                                    type="datetime"
                                    placement="bottom-start"
                                    placeholder="选择日期及具体时间">
                                </el-date-picker>
                            </el-form-item>
                        </div>

                        <div style="flex: 1;padding: 0 10px;">
                            <div class="title-form"><span style="color: red; font-size: 16px">* </span>活动参与者名单</div>
                            <el-form-item prop="signShowRange">
                                <el-radio v-model="signForm.signShowRange" :label="1">仅发布者可见</el-radio>
                                <el-radio v-model="signForm.signShowRange" :label="2">报名者可见</el-radio>
                            </el-form-item>
                        </div>
                    </div>

                    <div style="margin-bottom: 20px">
                        <div class="title-form"><span style="color: red; font-size: 16px">* </span>活动地点</div>
                        <el-form-item prop="location">
                            <el-input
                                    v-model="signForm.location"
                                    :show-word-limit="true"
                                    maxlength="100"
                                    placeholder="活动地点">
                            </el-input>
                        </el-form-item>
                    </div>

                    <div style="margin-bottom: 20px">
                        <div class="title-form"><span style="color: red; font-size: 16px">* </span>活动描述</div>
                        <el-form-item prop="detail">
                            <Editor :tinymce_height="'600'" id="1" :innerDrawer="innerDrawer = true" :value="signForm.detail" ref="child"></Editor>
                        </el-form-item>

                        <div style="margin-bottom: 20px;width: 100%;">

                            <el-upload accept=".pdf, .doc, .docx, .xls, .xlsx" action="" multiple :http-request="upload_file" :show-file-list="false" >
                                <el-button class="hand op sc" style="color: #FF9800; width: 168px;line-height: 0; border-radius: 30px; font-size: 18px;border: 2px solid #FF9800">
                                    <i class="el-icon-plus" style="font-weight: 600; margin-right: 10px"></i>
                                    上传附件
                                </el-button>
                            </el-upload>
                        </div>
                        <div class="title">
                            <div class="file-box hand op" v-for="(item, index) in fileList" :key="index">
                                <img src="https://oss.baigongbao.com/img/file.png" style="width: 24px; height: 20px; margin-right: 12px; vertical-align: middle">
                                <span style="font-size: 18px; vertical-align: middle">{{item.name}}</span>
                                <span style="background:#ff7575;color:#FFF;text-align:center;width:20px;height:20px;line-height:20px;border-radius:10px;float: right; margin: 5px 10px" @click="removeFile(index)">
                                    <i class="el-icon-close"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div style="margin-bottom: 20px">
                        <div class="title-form"><span style="color: red; font-size: 16px">* </span>事件封面</div>
                        <el-form-item prop="cover">
                            <el-upload class="upload-demo" drag multiple
                                       action=""
                                       :show-file-list="false" :http-request="uploadBack"
                                       :before-upload="beforeBackUpload">
                                <img v-if="signForm.cover !== '' " :src="signForm.cover" width="894px" height="420px">
                                <i class="el-icon-plus icon-plus"></i>
                                <div style="font-size: 20px">将图片拖到此处，或<em>点击上传</em></div>
                            </el-upload>
                        </el-form-item>
                    </div>


                    <div style="display: flex; margin-bottom: 20px">
                        <div style="flex: 1;padding: 0 10px;">
                            <div class="title-form">报名模板</div>
                            <el-form-item prop="templateId">
                                <el-select v-model="signForm.templateId" clearable placeholder="选择模板格式">
                                    <el-option v-for="(item, index) in optionTemplate" :key="index" :label="item.title" :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </div>

                        <div style="flex: 1;padding: 0 10px;">
                            <div class="title-form"><span style="color: red; font-size: 16px">* </span>活动类型</div>
                            <el-form-item prop="type">
                              <el-select v-model="signForm.type" clearable placeholder="选择活动类型">
                                <el-option label="课程讲座" :value="1"></el-option>
                                <el-option label="会议报名" :value="2"></el-option>
                                <el-option label="票务预订" :value="3"></el-option>
                                <el-option label="其他" :value="4"></el-option>
                              </el-select>
                            </el-form-item>
                        </div>

                        <div style="flex: 1;padding: 0 10px;">
                          <div class="title-form"><span style="color: red; font-size: 16px">* </span>是否收费</div>
                          <el-form-item prop="isCharge">
                            <el-radio v-model="signForm.isCharge" :label="1">收费</el-radio>
                            <el-radio v-model="signForm.isCharge" :label="0">免费</el-radio>
                          </el-form-item>
                        </div>
                    </div>
                  <div style="display: flex; margin-bottom: 20px"  v-if="signForm.isCharge === 1">
                    <div style="flex: 1;padding: 0 10px;">
                      <div class="title-form">活动金额</div>
                      <el-form-item prop="money">
                        <el-input-number v-model="signForm.money" :precision="2" :step="1" :min="0.01" size="small" style="width: 70%"></el-input-number>
                      </el-form-item>
                    </div>

                    <div style="flex: 1;padding: 0 10px;">

                    </div>

                    <div style="flex: 1;padding: 0 10px;" >

                    </div>
                  </div>

                </el-form>


                <div style="margin-bottom: 30px; display: flex; justify-content: center" v-if="JSON.stringify(this.data) === '{}'">
<!--                    <el-dropdown @command="selectDrafts">-->
<!--                        <div class="foot-btn" style="background-color: #F0F2F5; margin-right: 20px"  @click="createEvent(1)"><i class="el-icon-message"></i>{{draftBoxList.length}} 草稿箱</div>-->
<!--                        <el-dropdown-menu slot="dropdown">-->
<!--                            <div v-for="(item,index) in draftBoxList" :key="index" style="display: flex; justify-content: space-between">-->
<!--                                <el-dropdown-item :command="item.id">{{item.name}}</el-dropdown-item>-->
<!--                                <span @click="deleteDraftBox(item.id)" class="hand op" style="font-size:12px; color: #777777;display: inline-block; margin: 9px 10px">删除</span>-->
<!--                            </div>-->
<!--                        </el-dropdown-menu>-->
<!--                    </el-dropdown>-->

                    <div class="foot-btn hand op" style="background-color: #2970FF; margin-top: 20px; color: #FFFFFF" @click="createEvent(2)">确认创建事件</div>
                </div>

                <div v-else style="margin-bottom: 30px; display: flex; justify-content: center">
                    <div class="foot-btn hand op" style="background-color: #2970FF; margin-top: 20px; color: #FFFFFF" @click="editEvent(2)">确认修改事件</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import Editor from "@/components/common/Editor.vue";
    export default {
        name: "SignForm",
        components: {Editor},
        data() {
            return {
                tag: '',
                signForm: {
                    name: '',
                    startTime: '',
                    endTime: '',
                    title: '',
                    location: '',
                    detail: '',
                    enclosure: [],
                    cover: '',
                    templateId: '',
                    isCharge: 0,
                    activityTime: '',
                    signShowRange: 1,
                    money: 1.00,
                },

                rules: {
                    title: [
                        { required: true, message: '请输入名称', trigger: 'blur' },
                        { min: 4, max: 30, message: '名称长度必须在4-30的字符串之间', trigger: 'blur'}
                    ],
                    cover: [
                        { required: true, message: '请上传封面', trigger: 'blur' },
                    ],
                    activityTime: [
                        { required: true, message: '请活动开始时间', trigger: 'blur' },
                    ],
                    endTime: [
                        { required: true, message: '请选择报名截止时间', trigger: 'blur' },
                    ],
                    type: [
                        { required: true, message: '请选择活动类型', trigger: 'blur' },
                    ]
                },

                draftBoxList: [],

                page: 1,
                pageSize: 10,
                optionTemplate: [],
                fileList: [],

                pickerOptions: {
                    disabledDate (time) {
                        return time.getTime() < Date.now()//选当前时间之后的时间
                    }
                },

                saveId: '',
            }
        },

        computed: {
            user() {
                return this.$store.state.user
            },
            is_login() {
                return this.$store.state.is_login
            }
        },

        props: {
          data: {
              type: Object,
              default() {
                  return {}
              }
          }
        },

        mounted() {
            var that = this;
            this.getHotDiy();
            if (JSON.stringify(this.data) !== '{}') {
               this.getSaveInfo(this.data.id)
            } else {
                this.getEnrollList();
            }
        },

        methods: {
            // 获取报名详情
            getSaveInfo(id) {
                this.newApi.getEnrollInfo({id: id}).then(res =>{
                    this.signForm = res.data;
                    if (this.signForm.templateId === 0) {
                      this.signForm.templateId = ''
                    }
                    this.signForm.enclosure = JSON.parse(res.data.enclosure);
                    for (const item of this.signForm.enclosure) {
                        this.fileList.push(item);
                    }
                })
            },

            // 获取草稿箱事件
            getEnrollList() {
                this.newApi.getEnrollEventList({roomId: this.$route.params.id, status: 1}).then(res =>{
                    this.draftBoxList = res.data
                })
            },

            // 确定创建事件
            createEvent(e) {
                var that = this;
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        var params = {};
                        params.status = e;
                        //判断this.$route.params.id 是不是undefined
                        params.roomId = 0;
                        if (this.$route.params.id !== undefined) {
                            params.roomId = this.$route.params.id;
                        }
                        params.name = '活动报名';
                        params.startTime = that.utils.changeTimeType(new Date());
                        params.title = that.signForm.title;
                        params.location = that.signForm.location;
                        if (that.signForm.location == ''){
                            that.utils.err('活动地点不能为空');
                            return false;
                        }
                        params.detail = that.$refs.child.get_content();
                        if (params.detail == ''){
                            that.utils.err('活动描述不能为空');
                            return false;
                        }
                        params.cover = that.signForm.cover;
                        params.isCharge = that.signForm.isCharge;
                        if(that.signForm.isCharge == 1){
                            params.money = that.signForm.money;
                            //付费绑卡
                            if(that.user.isBank == 0){
                                that.utils.confirm("发布付费活动需要银行卡用于收取报名费，前往绑定？",function (){
                                    that.utils.b('/user/index/6');
                                });
                                return false;
                            }
                        }else{
                            params.money = 0.00;
                        }
                        params.templateId = that.signForm.templateId;
                        params.signShowRange = that.signForm.signShowRange;
                        params.type = that.signForm.type;
                        //比较两个时间大小
                        if (new Date(that.signForm.activityTime).getTime() <= new Date(that.signForm.endTime).getTime()) {
                            that.utils.err('活动开始时间需大于报名截止时间');
                            return false;
                        }
                        //报名截止时间必须大于当前时间2个小时
                        if (new Date(that.signForm.endTime).getTime() <= new Date(new Date().getTime() + 2 * 60 * 60 * 1000).getTime()) {
                            that.utils.err('报名截止时间需大于当前时间2小时');
                            return false;
                        }
                        params.activityTime = that.utils.changeTimeType(that.signForm.activityTime);
                        params.endTime = that.utils.changeTimeType(that.signForm.endTime);
                        params.enclosure = JSON.stringify(that.fileList);
                        that.newApi.addEnrollEvent(params).then(res => {
                            if (res.isSuccess === 1) {
                                that.utils.sus(res.data);
                                that.resetForm()
                                that.getEnrollList();
                                that.$emit('closeEvent', {eventType:'a'});
                                //刷新朋友圈
                                that.$EventBus.$emit("changeData", {changeData: 1});
                            }
                        })
                    }
                })
            },
            resetForm(){
              this.$refs["form"].resetFields();
              this.signForm.enclosure = [];

            },
            // 修改事件
            editEvent(e) {
                var that = this;
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        var params = {};
                        params.id = that.data.id;
                        params.status = e;
                        params.roomId = that.data.roomId;
                        params.name = '活动报名';
                        params.startTime = that.utils.changeTimeType(new Date());
                        params.title = that.signForm.title;
                        params.uuid = that.signForm.uuid;
                        params.location = that.signForm.location;
                        params.detail = that.$refs.child.get_content();
                        params.cover = that.signForm.cover;
                        params.isCharge = that.signForm.isCharge;
                        if(that.signForm.isCharge == 1){
                            params.money = that.signForm.money;
                        }else{
                            params.money = 0.00;
                        }
                        params.templateId = that.signForm.templateId;
                        if(that.signForm.templateId == ''){
                          params.templateId = 0;
                        }
                        params.signShowRange = that.signForm.signShowRange;
                        //比较两个时间大小
                        if (new Date(that.signForm.activityTime).getTime() <= new Date(that.signForm.endTime).getTime()) {
                            that.utils.err('活动开始时间需大于报名截止时间');
                            return false;
                        }
                        params.activityTime = that.utils.changeTimeType(that.signForm.activityTime);
                        params.endTime = that.utils.changeTimeType(that.signForm.endTime);
                        params.enclosure = JSON.stringify(that.fileList);
                        that.newApi.editEnroll(params).then(res => {
                            if (res.isSuccess === 1) {
                                that.utils.sus(res.data);
                                that.resetForm()
                                that.getEnrollList();
                                that.$emit('closeEvent', {eventType:'a'});
                                //发送广播 刷新signForm组件
                                that.$EventBus.$emit('reshEvent');
                                //刷新朋友圈
                                that.$EventBus.$emit("changeData", {changeData: 1});
                            }
                        })
                    }
                })
            },

            //附件上传
            upload_file:function(params){
                var that = this;
                var file = params.file;
                that.utils.upload(file,function(url){
                    if (!url){
                        return false;
                    }
                    var obj = {
                        name:url.name,
                        url:url.url,
                        hash:url.hash
                    };
                    console.log(url)
                    that.fileList.push(obj);
                    that.signForm.enclosure.push(file);
                })
            },

            // 移除附件
            removeFile(index) {
                var that = this;
                that.signForm.enclosure.splice(index, 1);
                that.fileList.splice(index, 1);
            },

            // 获取通用模板文档
            getHotDiy() {
                this.newApi.getMyDiyTemplate().then(res => {
                    this.optionTemplate = res.data
                })
            },
            // 上传图片
            uploadBack: function (params) {
                var file = params.file;
                var that = this;
                that.utils.upload(file, function (url) {
	                if (!url){
		                return false;
	                }
                    that.signForm.cover = url.url;
					console.log(that.signForm)
                })
            },

            beforeBackUpload(file) {
                const isJPG = file.type;
                const isLt2M = file.size / 1024 / 1024 < 20;
                if (!isLt2M) {
                    this.$message.error('上传头像图片大小不能超过 20MB!');
                }
                return isJPG && isLt2M;
            },
        }
    }
</script>

<style scoped>
    .sign {
        width: 100%;
    }

    .sign .title-form {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 12px;
    }

    .sign .title{
        overflow: hidden;
        overflow-y: scroll;
        max-height: 300px;
        line-height: 26px;
        font-size: 15px;
    }

    .sign .title::-webkit-scrollbar {
        display: none;
    }

    .sign .file-box {
        height: 32px;
        padding: 8px 10px;
        margin-bottom: 4px;
        background-color: #F1F1F1;
    }

    .sign .upload-demo {
        vertical-align: middle;
    }

    /deep/ .upload-demo .el-upload-dragger {
        width: 894px;
        height: 420px;
        background-color: #FBFBFB;
    }

    .sign .icon-plus {
        color: #2970FF;
        font-size: 44px;
        font-weight: 600;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        margin: 160px 0 14px 0;
    }

    .sign .foot-btn {
        width: 128px;
        height: 20px;
        font-size: 16px;
        border-radius: 5px;
        text-align: center;
        padding: 10px;
    }

    .sign .no-change:hover {
        color: #FFFFFF;
        background-color: #6d737b;
    }

    .sign .no-change {
        width: calc(100% - 32px);
        height: 40px;
        margin:0 12px;
        padding: 0 0 0 8px;
    }


</style>
