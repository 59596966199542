<template>
	<div class="user">
		<div>
			<div style="float: left;width: 60px;height: 60px;" class="hand op" @click="toMySpace">
				<img style="width: 45px;height: 45px;border-radius: 50%;margin-top: 7px;margin-left: 7px;"  :src="data.headIco +'-200x200'">
			</div>
			<div style="float: left;text-overflow: ellipsis; white-space: nowrap; overflow: hidden;width: 160px;height: 60px;">
				<div style="height: 22px;font-weight: bold;line-height: 20px;margin-top: 10px;" v-if="data.userName">{{data.userName}}</div>
				<div style="height: 22px;font-weight: bold;line-height: 20px;margin-top: 10px;" v-else>{{data.nickName}}</div>
<!--				<div style="height: 22px;font-size: 12px; line-height: 20px; color: #c1c1c1;"><span v-if="data.address.cityName.length > 0">{{data.address.cityName[1]}}</span><span v-if="data.major && data.address.cityName"> | </span>{{data.major}}</div>-->
			</div>
		</div>
	</div>
</template>

<script>

	export default {
		name:"User",
		components: {
		},
		data () {
			return {

			}
		},
		props:{
            data:{
              type:Object,
              default() {

                return {
                  headIco:"",
                  major:"",
                  nickName:"",
                  userName:"",
                  address:{
                      cityName:[]
                  }
                }
              }
            },
            onClickBtn:{
                type:Function,
                default() {
                    return function(){
                        this.a('/user/info/' + this.data.uuid);
                    }
                }
            },
            btn_text:{
                type:String,
                default() {
                    return "查看"
                }
            },
            need_detail:{
                type:Boolean,
                default() {
                    return true
                }
            }
        },
		computed: {
			user() {
				return this.$store.state.user
			},
			is_login(){
				return this.$store.state.is_login
			},
		},
		created:function(){

		},
		mounted: function () {

		},
		methods:{
			// 点击进入个人空间
			toMySpace() {
        if (!this.utils.toAuth(1)) {
          return false;
        }
          if (this.data.userType === 1) {
            this.$router.push({ path: '/circle/mySpace/' + this.data.uuid})
          }
          if (this.data.userType === 2) {
            this.$router.push({ path: '/circle/organSpace/' + this.data.uuid})
          }
          if (this.data.userType === 3) {
            this.$router.push({ path: '/circle/organSpace/' + this.data.uuid})
          }
        }
			},
	}

</script>

<style type="text/css">
	.el-divider--vertical{
		margin:0 3px;
	}
</style>
<style scoped>

    .user{

        height:54px;overflow:hidden;width: 100%;
    }
    .user .head_ico{
        height:40px; width:40px;
        border-radius:50%;overflow:hidden;
        float:left; margin:5px;
    }
    .user .name_detail{
        float:left;line-height:20px;height:40px; margin:5px;width: calc(100% - 50px - 76px);
    }
    .user .name_detail.no_detail{
        height:40px;line-height:40px;
    }
    .user .nick_name{
        font-size:16px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;
    }
    .user .detail{
        color:#a6a6a6;font-size:13px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;
    }
    .user .right_btn{
        border:1px solid #384a92;border-radius:15px;padding:2px 10px;
        color:#384a92;line-height:20px;float: right; font-size:12px;
    }
	.user .pay-btn {
		width: 26px;
		height: 16px;
		font-weight: 600;
		margin: 12px 0 0 12px;
		padding: 4px 12px;
		font-size: 12px;
		border: 2px #2970FF solid;
		border-radius: 20px;
		color:#2970FF;
	}

	.user .friend-btn {
		width: 38px;
		height: 16px;
		font-weight: 600;
		margin: 12px 0 0 12px;
		padding: 4px 6px;
		font-size: 12px;
		border: 2px #2970FF solid;
		border-radius: 20px;
		color: #2970FF;
	}

</style>
